
.square {
    width: 50px;
    height: 50px;
    border: 1px solid #fafafa;
    cursor: pointer;
    position: relative;
    border-radius: 10px;
    margin: 0.1em;
    transition: transform 0.4s ease-out, background-color 0.3s ease-in-out,filter 0.2s ease;
}

.square.animated {
    transform: scale(0.75);
    filter: saturate(0%);
}


.flagged {
    margin: 0.1em;

    border: 3px solid var(--flagged-border-color);
    border-radius: 10px;
    /*background-color: var(--flagged-overlay-color);*/
    position: absolute;
    top: -0.2em;
    display: flex;
    justify-content: center;
    align-items: center;
    left: -0.2em;
    right:-0.2em;
    bottom: -0.2em;

    animation-duration: 0.2s;
    animation-name: flagged;
    animation-delay: 0.1s;
    animation-fill-mode: backwards;
}

@keyframes flagged {
    0% {
        transform: scale(0, 0)
    }
    100% {
        transform: scale(1, 1)
    }
}

.selected {
    border: 3px solid var(--highlight-color);
    border-radius: 10px;
    background-color: rgba(227, 227, 227, 0.45);
    position: absolute;
    top: -0.2em;

    left: -0.2em;
    right:-0.2em;
    bottom: -0.2em;
    box-shadow: inset 0 0 4px 0px var(--shadow-color);
    animation-duration: 0.1s;
    animation-name: flagged;

    animation-fill-mode: backwards;
}
