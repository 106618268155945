/* App.css */
body {
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
  background-color: var(--background-color);

}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 1rem;
}

.App-header {
  font-size: 2rem;
  margin-bottom: 1rem;
  color: var(--highlight-color);
}

.App-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.explainations *{
  margin-top:0.2em;
  font-size: 0.7em;
}
.explainations .special-case{
  font-size: 1em;
}
.snackbar .notistack-Snackbar>div{
  background-color: var(--highlight-color);
  color:var(--text-color)
}
