.hint-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    margin: 5px;
    padding: 5px;
    background-color: var(--background-color);
    border-radius: 4px;
    border: 1px solid var(--shadow-color);
    font-weight: bold;
    color: var(--text-color);

    -khtml-user-select: none;
    -webkit-user-select: none;

    /*
      Introduced in IE 10.
      See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
    */
    -ms-user-select: none;
    user-select: none;
}
.explainations .hint-box{
    width: 0.7em;
    height: 0.7em;
}



.hint-wrapper {
    display: flex;
    align-items: flex-end;
}

.hint-container {
    position: relative;
    display: inline-block;
    height: 100%;
    width: 50%;
}

.hint-text {
    position: absolute;
    bottom: 0;
    left: 20%;
    transform: translateX(-50%);
}

.separator {
    margin: 0 4px;
}

.hint-enter {
    opacity: 0;
    transform: translateY(20px) translateX(-50%);
    left:50%
}

.hint-enter-active {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
    transition: opacity 500ms, transform 500ms;
}

.hint-exit {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
}

.hint-exit-active {
    opacity: 0;
    transform: translateY(-20px) translateX(-50%);
    transition: opacity 500ms, transform 500ms;
}
