body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --background-color: #f0f0f0;
  --text-color: #000000;
  --square-color-1: #F44336;
  --square-color-2: #2196F3;
  --square-color-3: #FFEB3B;
  --square-color-4: #008000;
  --square-color-5: #FF9800;
  --square-color-6: #9C27B0;
  --shadow-color:rgba(225, 225, 225, 0.79);
  --highlight-color:rgba(189, 188, 188, 0.75);
  --title-color:#333;
  --flagged-border-color:rgba(15, 175, 3, 0.63);
  --flagged-overlay-color:rgba(32, 239, 18, 0.11);
}
