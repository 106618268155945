.game-board {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.game-row {
    display: flex;
    align-items: center;
}

.game-column-hints {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 2px;
}

/* Square component styles */
/*.square {*/
/*    width: 50px;*/
/*    height: 50px;*/
/*    border: 1px solid #000;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    cursor: pointer;*/
/*    margin: 1px;*/
/*}*/

/* HintBox component styles */
.hint-box {
    width: 30px;
    height: 30px;
    border: 1px solid #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1px;
    background-color: #fff;
    font-size: 14px;

}
button.hint-box:disabled{
    background-color: #cdcdcd;
    color: #adadad;
}
.win-screen{
    position: absolute;
    top:0;
    bottom:0;
    width: 100%;
    height: 100%;
    background-color: rgba(93, 91, 91, 0.63);
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}
.win-card{
    padding:1em;
margin:5vw;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    width: fit-content;
    background-color: var(--background-color);
    border-radius: 10px;
    color: var(--highlight-color);

}
.win-card a{
    padding: 0.3em;
    background-color: var(--highlight-color);
    color: var(--background-color);
    font-size: 1.2em;
    margin: 0.2em;
    border-radius: 10px;
    text-decoration: none;
    font-weight: bold;
}
.win-card button{
    padding: 0.3em;
    background-color: var(--background-color);
    color: var(--highlight-color);
    font-size: 1.2em;
    margin: 0.2em;
    border-radius: 10px;
    text-decoration: none;
    font-weight: bold;
    border:solid 1px var(--highlight-color);
}
.win-card .win-actions{
    display: flex;
}
