.theme-switcher{
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 100;
    background-color: var(--background-color);
    border-radius: 16px;
    width: 280px;
    padding:0.2em;
    transform:translateX(-0.5em);
    box-shadow: 1px 1px 8px 5px  var(--shadow-color);
    border:solid 0.5px var(--shadow-color)
}
.theme-switcher .square{
    height: 20px;
    width: 20px;
}
.theme-button{
    border-radius: 16px;
    margin:0.1em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 0.7em;
    min-height: 2em;
    padding-top: 0.1em;
    padding-bottom: 0.1em;
}
.squares-container {
    display: flex;
    flex-direction: row;
}
